<template>
  <span class="search_field">
    <el-button @click="searchClick" style="margin-left: 10px;padding: 10px 0;width: 90px">
      自定义查询
    </el-button>
    <el-drawer title="自定义查询" :visible.sync="show" size="90%">
      <!-- <p slot="title" class="title">自定义查询</p> -->
      <div class="see">
        <p style="font-size: 14px; color: #333">
          预览<span style="font-size: 12px; color: #999"
            >（可拖拽调整顺序）</span
          >
        </p>
        <el-row :gutter="32" class="content">
          <draggable
            v-model="selectArr"
            @update="datadragEnd"
            v-bind="{ animation: 200 }"
          >
            <template v-for="(item, index) in selectArr">
              <el-col :xs="24" :sm="4" :key="index">
                <el-input
                  v-model="item.label"
                  size="small"
                  style="width: 100%; margin-bottom: 12px"
                  readonly
                />
              </el-col>
            </template>
          </draggable>
        </el-row>
      </div>
      <div class="select-box">
        <div class="selectTitle">
          <p>请勾选显示的查询条件</p>
          <div class="btn">
            <el-button
              size="small"
              type="primary"
              @click.prevent.native="handleCheckAll"
              >全选</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click.prevent.native="handleCheckCalcelAll"
              >反选</el-button
            >
          </div>
        </div>
        <el-row>
          <div class="search" inline>
            <el-row :gutter="32">
              <div v-for="(item, index) in searchAllParam" :key="index">
                <el-col :xs="24" :sm="4">
                  <el-checkbox-group
                    v-model="box"
                    size="small"
                    @change="checkAllGroupChange"
                  >
                    <el-checkbox :label="item.label" border></el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </div>
            </el-row>
          </div>
        </el-row>
      </div>
      <div class="footer">
        <el-button @click="calcel">取消</el-button>
        <el-button type="primary" @click="saveClick">确定</el-button>
      </div>
    </el-drawer>
  </span>
</template>
<script>
import draggable from "vuedraggable";
import { doUserCustom } from "@/api/public";

export default {
  components: { draggable },
  props: {
    searchAllParam: {
      type: Array,
      default: () => {
        return [];
      },
    },
    code: {
      type: String,
      default: () => {
        return "";
      },
    },
    searchParamFromApi: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
      box: [],
      selectArr: [],
      checkAll: false,
      selectBox: [],
    };
  },
  methods: {
    searchClick() {
      this.show = true;
    },
    // 全选
    handleCheckAll() {
      this.searchAllParam.map((item) => {
        if (!this.box.includes(item.label)) {
          this.box.push(item.label);
        }
      });
      this.selectArr = this.searchAllParam;
    },
    // 反选
    handleCheckCalcelAll() {
      // 没有选择的情况选反选则为全选
      if (this.box.length === 0) {
        this.searchAllParam.map((item) => {
          this.box.push(item.label);
        });
        this.selectArr = this.searchAllParam;
      } else {
        // 有选择的情况是反选与之前选择的取反
        let newArr = [];
        let newBox = [];
        this.searchAllParam.map((item) => {
          if (!this.box.includes(item.label)) {
            newArr.push(item);
            newBox.push(item.label);
          }
        });
        this.selectArr = newArr;
        this.box = newBox;
      }
    },
    checkAllGroupChange(val) {
      let newArr = [];
      val.map((i) => {
        this.searchAllParam.map((item) => {
          if (i === item.label) {
            newArr.push(item);
          }
        });
      });
      this.selectArr = newArr;
    },
    datadragEnd(evt) {
      evt.preventDefault();
      let searchAllParam = this.selectArr;
      let newSelectArr = [];
      searchAllParam.map((item) => {
        newSelectArr.push(item);
      });
      this.selectArr = newSelectArr;
    },
    async saveClick() {
      let search = [];
      if (this.selectArr.length !== 0) {
        this.selectArr.map((item) => {
          search.push(item.name);
        });
      }
      await doUserCustom({
        moduleCode: this.code,
        fieldData: search
      });
      this.$emit("searchList");
      this.show = false;
    },
    calcel() {
      this.show = false;
    },
  },
  watch: {
    searchParamFromApi: {
      handler(val) {
        if(val) {
          let newArr = [];
          let newBox = [];
          val.map((item) => {
            this.searchAllParam.map((i) => {
              if (i.name == item) {
                newArr.push(i);
                newBox.push(i.label);
              }
            });
          });
          this.selectArr = newArr;
          this.box = newBox;
        }
      },
      immediate: true
    },
  },
};
</script>
<style lang="scss" scope>
.el-button {
  border-radius: 0px !important;
}

.modal .ivu-checkbox-wrapper {
  width: 110px;
  text-align: right;
  /* margin: 0 8px; */
}
.modal .ivu-checkbox-group {
  display: inline-block;
}
.modal .ivu-checkbox {
  float: left;
  margin-top: 2px;
}
.modal .ivu-form-item {
  margin-bottom: 0;
}
.modal .ivu-input-wrapper {
  width: 100px;
}
.modal .ivu-card {
  margin-bottom: 10px;
}
.modal .title {
  /* margin-bottom: 20px; */
  height: 54px;
  line-height: 54px;
  padding: 0 16px;
  background-color: #2d8cf0;
  color: #fff;
}
.modal .ivu-col {
  margin-bottom: 10px;
}
.title h2 {
  float: left;
}
.title .btn {
  float: right;
}
.selectTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 32px;

  >p {
    color: #333;
    font-size: 14px;
    font-weight: 400px;
  }
}

.selectTitle .btn {
  float: right;
}
.modal .ivu-modal-body {
  padding: 0 0 16px 0;
}

// .el-drawer__header {
//   border-bottom: 1px solid #e1e4eb; 
// }

.see {
  width: 90%;
  margin: 0 auto 86px;

  .content {
    background-color: #f0f0f3;
    width: 100%;
    min-height: 100px;
    padding: 12px 0;
  }
}
.select-box {
  width: 90%;
  margin: 0 auto;
}

.el-checkbox.is-bordered {
  width: 100%;
  margin-bottom: 12px;
}

.footer {
  text-align: center;
  position: fixed;
  width: 80%;
  height: 90px;
  line-height: 90px;
  bottom: 0;
  right: 0;
  border-top: 1px solid #e1e4eb;
}

</style>
